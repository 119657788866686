import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "../Login.css";
const Login = () => {
  const [msg, setmsg] = useState({ msg: "", status: "" });
  const formRef = useRef(null);
  const [role, setRole] = useState(localStorage.getItem("role"));
  let decodedRole = atob(role);

  useEffect(() => {
    if (decodedRole === "user") {
      // window.location.href = '/Admin/Dashboard';
    }
  }, [decodedRole]);

  const validateForm = () => {
    const mobileNumber = formRef.current.mobileNumber.value;
    const password = formRef.current.password.value;

    if (!mobileNumber || mobileNumber.length !== 10) {
      toast.error("Please Enter a Valid Mobile Number");
      return false;
    }
    if (!password) {
      toast.error("Please Enter Your Password");
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    let formData = new FormData(formRef.current);
    let data = {};
    formData.forEach((value, key) => {
      if (key === "mobileNumber") {
        data[key] = "+91" + value;
      } else {
        data[key] = value;
      }
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://api-v1.aragon-ai.com/v1/auth/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem("authToken", response.data.data.accessToken);
          localStorage.setItem("role", btoa(response.data.data.role));
          console.log(response.data.data.role);
          if (response.data.data.role === "user") {
            toast.success(response.data.message);
            setmsg({ msg: response.data.message, status: "success" });
            window.location.href = "/";
          } else {
            toast.error("User Not Found");
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
          setmsg({ msg: error.response.data.message, status: "error" });
        } else {
          toast.error("An unexpected error occurred.");
        }
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="d-flex login-container p-3 align-items-start align-items-md-center">
        <div className="col-12 col-md-6 d-none d-md-block login-form-bg rounded overflow-hidden">
          <img src="/assets/img/login-bg.png" alt="Login Background" />
        </div>
        <div className="login-form-main col-12 col-md-6 signup-form-main d-flex flex-column justify-content-center align-items-center text-center px-5">
          <div className="col-12 d-md-block">
            <img
              className="Login-form-logo mb-3"
              src="/assets/img/logo.png"
              alt="Logo"
            />
            <h2 className="signup-title text-start">Login to ApiAdmin</h2>
            <h4 className="text-start">Welcome Back 👋</h4>
          </div>

          <form
            className="col-12 text-start d-flex flex-wrap custom-form py-4"
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <div className="col-12 px-2 mb-3">
              <label>Mobile Number</label>
              <div className="input-group">
                <span
                  className="input-group-text custom-input-group-text"
                  id="basic-addon1"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.4221 11.9539C4.66368 10.6314 4.29748 9.55158 4.07667 8.45696C3.7501 6.83806 4.49745 5.25665 5.7355 4.24758C6.25876 3.82111 6.85858 3.96682 7.168 4.52192L7.86654 5.77513C8.42023 6.76845 8.69707 7.26511 8.64216 7.79167C8.58726 8.31823 8.2139 8.74708 7.46718 9.6048L5.4221 11.9539ZM5.4221 11.9539C6.95721 14.6306 9.36627 17.041 12.0461 18.5779M12.0461 18.5779C13.3686 19.3363 14.4484 19.7025 15.543 19.9233C17.1619 20.2499 18.7434 19.5025 19.7524 18.2645C20.1789 17.7413 20.0332 17.1414 19.4781 16.832L18.2249 16.1334C17.2315 15.5797 16.7349 15.3029 16.2083 15.3578C15.6818 15.4127 15.2529 15.7861 14.3952 16.5328L12.0461 18.5779Z"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="text"
                  name="mobileNumber"
                  className="form-control custom-input"
                  placeholder="Enter Your Mobile Number"
                />
              </div>
            </div>
            <div className="col-12 px-2 mb-3">
              <label>Password</label>
              <div className="input-group">
                <span
                  className="input-group-text custom-input-group-text"
                  id="basic-addon1"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.491 15.5H14.5M9.5 15.5H9.50897"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.26781 18.8447C4.49269 20.515 5.87613 21.8235 7.55966 21.9009C8.97627 21.966 10.4153 22 12 22C13.5847 22 15.0237 21.966 16.4403 21.9009C18.1239 21.8235 19.5073 20.515 19.7322 18.8447C19.879 17.7547 20 16.6376 20 15.5C20 14.3624 19.879 13.2453 19.7322 12.1553C19.5073 10.485 18.1239 9.17649 16.4403 9.09909C15.0237 9.03397 13.5847 9 12 9C10.4153 9 8.97627 9.03397 7.55966 9.09909C5.87613 9.17649 4.49269 10.485 4.26781 12.1553C4.12105 13.2453 4 14.3624 4 15.5C4 16.6376 4.12105 17.7547 4.26781 18.8447Z"
                      stroke="black"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M7.5 9V6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5V9"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="password"
                  className="form-control custom-input"
                  placeholder="Enter Your Password"
                  name="password"
                />
              </div>
            </div>
            <div className="col-12 px-2">
              <button className="btn custom-btn w-100" type="submit">
                Login
                <span className="ps-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 18V6"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M16 11.9995H4"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 8C12 8 16 10.946 16 12C16 13.0541 12 16 12 16"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </button>
            </div>
            <div className="col-12 d-flex justify-content-between p-2 align-items-center">
              <p>
                <input type="checkbox" className="me-1" />
                Remember Me
              </p>
              <p>Forgot Password?</p>
            </div>
          </form>

          <div className="login-link">
            <p className="secoundary-color">
              Don’t have an account? <a href="/signup">Sign Up</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
