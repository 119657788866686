import React, { useEffect, useState } from 'react';
import Header from './UI/Header';
import Footer from './UI/Footer';

const AboutUs = () => {

    return (
        <>
            <Header data={{ key: 'About Us' }} />

            <div className="container text-center section-padding">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 text-center">
                        <h1 className="heading">About us</h1>
                        <p className='opacity-50 small-heading'>
                            {'{Revolutionizing Visual Creation through AI}'}
                        </p>
                    </div>
                    <div className="col-12 d-flex flex-wrap align-items-center">
                        <div className="col-12 col-md-6 text-start" style={{ padding: '0 10%' }}>
                            <img src="/assets/img/about_us_img_1.png" className='w-100' />
                        </div>
                        <div className="col-12 col-md-6 text-start">
                            <h3 style={{ fontWeight: 'bold' }}>
                                What is APIAdmin ?
                            </h3>
                            <p className='opacity-50'>
                                Established in 2017 in Surat, Gujarat, APIAdmin has rapidly grown to become a leading force in AI-powered image enhancement. Our self-financing, innovative platform serves a global clientele, spanning nearly every country, providing cutting-edge solutions that automatically enhance photo quality with precision. By harnessing advanced neural networks and sophisticated algorithms.
                            </p>
                            <div className='text-center text-md-start'>
                                <button className='custom-btn' style={{ borderRadius: '10px' }}>
                                    <span className='pe-2'>
                                        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.5 10.75C17.1862 10.75 16.9055 10.9454 16.7966 11.2397L16.6861 11.5384C16.6094 11.7456 16.5363 11.9229 16.4983 12.0041C16.4171 12.042 16.2456 12.1094 16.0384 12.1861L15.7397 12.2966C15.4454 12.4055 15.25 12.6862 15.25 13C15.25 13.3138 15.4454 13.5945 15.7397 13.7034L16.0384 13.8139C16.2456 13.8906 16.4171 13.958 16.4983 13.9959C16.5363 14.0771 16.6094 14.2544 16.6861 14.4616L16.7966 14.7603C16.9055 15.0546 17.1862 15.25 17.5 15.25C17.8138 15.25 18.0945 15.0546 18.2034 14.7603L18.3139 14.4616C18.3906 14.2544 18.4637 14.0771 18.5017 13.9959C18.5829 13.958 18.7544 13.8906 18.9616 13.8139L19.2603 13.7034C19.5546 13.5945 19.75 13.3138 19.75 13C19.75 12.6862 19.5546 12.4055 19.2603 12.2966L18.9616 12.1861C18.7544 12.1094 18.5829 12.042 18.5017 12.0041C18.4637 11.9229 18.3906 11.7456 18.3139 11.5384L18.2034 11.2397C18.0945 10.9454 17.8138 10.75 17.5 10.75Z" fill="white" />
                                            <path d="M12.4869 5.93968C12.9972 5.68677 13.5964 5.68677 14.1068 5.93968C14.3794 6.07479 14.671 6.36755 14.9013 6.5989C15.1327 6.82922 15.4254 7.12078 15.5605 7.39343C15.8134 7.90378 15.8134 8.50298 15.5605 9.0133C15.4254 9.286 15.1921 9.5183 14.9607 9.7486L13.0609 11.6485L9.85156 8.43942L11.7516 6.53947C11.9819 6.30812 12.2142 6.07479 12.4869 5.93968Z" fill="white" />
                                            <path d="M8.7908 9.5L12 12.709L4.24861 20.4604C4.01828 20.6918 3.78598 20.9251 3.51333 21.0602C3.00298 21.3131 2.40378 21.3131 1.89343 21.0602C1.62078 20.9251 1.32922 20.6324 1.0989 20.401C0.867552 20.1707 0.574793 19.8791 0.439682 19.6065C0.186772 19.0961 0.186772 18.4969 0.439682 17.9866C0.574793 17.7139 0.808122 17.4816 1.03947 17.2513L8.7908 9.5Z" fill="white" />
                                            <path d="M8.5 0.75C8.1862 0.75 7.90552 0.9454 7.79661 1.23972L7.68607 1.53845C7.60942 1.7456 7.53627 1.92286 7.49833 2.00408C7.41711 2.04203 7.2456 2.10942 7.03845 2.18607L6.73972 2.29661C6.4454 2.40552 6.25 2.68617 6.25 3C6.25 3.31383 6.4454 3.59448 6.73972 3.70339L7.03845 3.81393C7.2456 3.89058 7.41711 3.95797 7.49833 3.99592C7.53627 4.07714 7.60942 4.2544 7.68607 4.46155L7.79661 4.76028C7.90552 5.0546 8.1862 5.25 8.5 5.25C8.8138 5.25 9.0945 5.0546 9.2034 4.76028L9.3139 4.46155C9.3906 4.2544 9.4637 4.07714 9.5017 3.99592C9.5829 3.95797 9.7544 3.89058 9.9616 3.81393L10.2603 3.70339C10.5546 3.59448 10.75 3.31383 10.75 3C10.75 2.68617 10.5546 2.40552 10.2603 2.29661L9.9616 2.18607C9.7544 2.10942 9.5829 2.04203 9.5017 2.00408C9.4637 1.92286 9.3906 1.7456 9.3139 1.53845L9.2034 1.23972C9.0945 0.9454 8.8138 0.75 8.5 0.75Z" fill="white" />
                                            <path d="M17.5 0.75C17.1862 0.75 16.9055 0.9454 16.7966 1.23972L16.6861 1.53845C16.6094 1.7456 16.5363 1.92286 16.4983 2.00408C16.4171 2.04203 16.2456 2.10942 16.0384 2.18607L15.7397 2.29661C15.4454 2.40552 15.25 2.68617 15.25 3C15.25 3.31383 15.4454 3.59448 15.7397 3.70339L16.0384 3.81393C16.2456 3.89058 16.4171 3.95797 16.4983 3.99592C16.5363 4.07714 16.6094 4.2544 16.6861 4.46155L16.7966 4.76028C16.9055 5.0546 17.1862 5.25 17.5 5.25C17.8138 5.25 18.0945 5.0546 18.2034 4.76028L18.3139 4.46155C18.3906 4.2544 18.4637 4.07714 18.5017 3.99592C18.5829 3.95797 18.7544 3.89058 18.9616 3.81393L19.2603 3.70339C19.5546 3.59448 19.75 3.31383 19.75 3C19.75 2.68617 19.5546 2.40552 19.2603 2.29661L18.9616 2.18607C18.7544 2.10942 18.5829 2.04203 18.5017 2.00408C18.4637 1.92286 18.3906 1.7456 18.3139 1.53845L18.2034 1.23972C18.0945 0.9454 17.8138 0.75 17.5 0.75Z" fill="white" />
                                        </svg>
                                    </span>
                                    Access API
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-flex flex-wrap align-items-center flex-md-row-reverse mt-4">
                        <div className="col-12 col-md-6 text-end" style={{ padding: '0 10%' }}>
                            <img src="/assets/img/about_us_img_2.png" className='w-100' />
                        </div>
                        <div className="col-12 col-md-6 text-start">
                            <h3 style={{ fontWeight: 'bold' }}>
                                Why Choose APIAdmin ?
                            </h3>
                            <p className='opacity-50'>
                                APIAdmin stands out as the ideal platform for AI image generation due to its unmatched quality, speed, and customer-centric approach. Whether you need simple graphics or complex designs, APIAdmin’s cutting-edge algorithms guarantee top-notch results every time.
                                <br />
                                One of the key advantages of APIAdmin is its lightning-fast performance.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="custom-divider"></div>


            <div className="container text-center section-padding">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 text-center">
                        <h1 className="heading">About Our Company</h1>
                        <p className='opacity-50 small-heading'>
                            We specialize in providing seamless API credit solutions to empower your business.
                        </p>
                    </div>
                    <div className="col-12 d-flex flex-wrap">
                        <div className="col-12 col-md-6 p-2">
                            <div className="about-company-card text-start">
                                <span>
                                    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 14C17.5817 14 14 17.5817 14 22C14 26.4182 17.5817 30 22 30C26.4182 30 30 26.4182 30 22C30 20.8954 30.8954 20 32 20C33.1046 20 34 20.8954 34 22C34 28.6274 28.6274 34 22 34C15.3726 34 10 28.6274 10 22C10 15.3726 15.3726 10 22 10C23.1046 10 24 10.8954 24 12C24 13.1046 23.1046 14 22 14Z" fill="#9371F0" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 4C12.0589 4 4 12.0589 4 22C4 31.9412 12.0589 40 22 40C31.9412 40 40 31.9412 40 22C40 20.7646 39.8758 19.56 39.64 18.3978C39.4202 17.3154 40.1196 16.2597 41.202 16.04C42.2846 15.8202 43.3402 16.5196 43.56 17.6021C43.8488 19.0248 44 20.4958 44 22C44 34.1502 34.1502 44 22 44C9.84974 44 0 34.1502 0 22C0 9.84974 9.84974 0 22 0C23.5042 0 24.9752 0.15126 26.3978 0.44004C27.4804 0.65978 28.1798 1.71544 27.96 2.79794C27.7402 3.88044 26.6846 4.57984 25.6022 4.3601C24.44 4.12418 23.2354 4 22 4Z" fill="#9371F0" />
                                        <path d="M38.2841 2.15546C37.9689 0.947218 36.9926 0.205418 35.9452 0.0380981C34.9356 -0.123164 33.9128 0.235278 33.1818 0.966158L30.8829 3.26492C28.3441 5.80374 28.1317 8.93236 28.9817 12.1901L20.5857 20.586C19.8047 21.367 19.8047 22.6334 20.5857 23.4144C21.3668 24.1956 22.6331 24.1956 23.4141 23.4144L31.8101 15.0185C35.0677 15.8685 38.1966 15.656 40.7354 13.1172L43.034 10.8184C43.765 10.0875 44.1234 9.06464 43.9622 8.05514C43.7948 7.00766 43.0529 6.03124 41.8447 5.716L39.021 4.97924L38.2841 2.15546Z" fill="#9371F0" />
                                    </svg>
                                </span>
                                <h3 className='mt-2'><b>Our Mission</b></h3>
                                <p className='opacity-50 m-0'>
                                    Our mission is to empower creativity by providing a seamless platform that transforms ideas into stunning visuals through advanced.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <div className="about-company-card text-start">
                                <span>
                                    <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6458 0.500065L16.4653 0.500025C14.9461 0.499685 13.9293 0.499445 12.9534 0.737665C12.5116 0.845545 12.08 0.989525 11.6638 1.16829C10.74 1.56507 9.9507 2.17303 8.79064 3.06659L8.64944 3.17533L8.58546 3.22457C6.49302 4.83537 4.83492 6.1118 3.61594 7.25598C2.3702 8.42524 1.45182 9.573 0.970118 10.9739C0.574678 12.1239 0.425898 13.3369 0.534438 14.5436C0.667118 16.0188 1.29164 17.3358 2.22902 18.7354C3.14404 20.1018 4.45528 21.6854 6.10472 23.6772L14.2205 33.4784C15.6934 35.2574 16.9044 36.7198 18.0158 37.7218C19.1828 38.7738 20.4336 39.5 22 39.5C23.5664 39.5 24.8172 38.7738 25.9842 37.7218C27.0956 36.7198 28.3066 35.2574 29.7796 33.4784L37.8952 23.6772C39.5448 21.6854 40.856 20.1018 41.771 18.7354C42.7084 17.3358 43.3328 16.0188 43.4656 14.5436C43.5742 13.3369 43.4254 12.1239 43.0298 10.9739C42.5482 9.573 41.6298 8.42524 40.384 7.25598C39.165 6.11182 37.507 4.83539 35.4146 3.22461L35.3506 3.17533L35.2094 3.06659C34.0492 2.17303 33.26 1.56507 32.3362 1.16829C31.92 0.989525 31.4884 0.845545 31.0466 0.737665C30.0706 0.499445 29.054 0.499685 27.5346 0.500025L27.3542 0.500065H16.6458ZM18 11.5C17.1716 11.5 16.5 12.1716 16.5 13C16.5 13.8284 17.1716 14.5 18 14.5H26C26.8284 14.5 27.5 13.8284 27.5 13C27.5 12.1716 26.8284 11.5 26 11.5H18Z" fill="#9371F0" />
                                    </svg>
                                </span>
                                <h3 className='mt-2'><b>Our Values</b></h3>
                                <p className='opacity-50 m-0'>
                                    At the core of our platform, we value innovation, accessibility, and integrity. We are committed to continuously improving our technology.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <div className="about-company-card text-start">
                                <span>
                                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3711 1.90104C15.4258 3.00426 14.5758 3.94292 13.4726 3.9976C9.32864 4.20296 7.39886 4.81622 6.10754 6.10754C4.81622 7.39886 4.20296 9.32864 3.9976 13.4726C3.94292 14.5758 3.00426 15.4258 1.90104 15.3711C0.797837 15.3165 -0.0521831 14.3778 0.00249692 13.2746C0.213377 9.01964 0.845337 5.7129 3.27912 3.27912C5.7129 0.845337 9.01964 0.213377 13.2746 0.00249692C14.3778 -0.0521831 15.3165 0.797837 15.3711 1.90104ZM26.629 1.90104C26.6836 0.797837 27.6222 -0.0521831 28.7256 0.00249692C32.9804 0.213377 36.2872 0.845337 38.721 3.27912C41.1548 5.7129 41.7868 9.01964 41.9976 13.2746C42.0522 14.3778 41.2022 15.3165 40.099 15.3711C38.9958 15.4258 38.0572 14.5758 38.0024 13.4726C37.7972 9.32864 37.1838 7.39886 35.8926 6.10754C34.6012 4.81622 32.6714 4.20296 28.5276 3.9976C27.4242 3.94292 26.5742 3.00426 26.629 1.90104ZM1.90104 26.629C3.00426 26.5742 3.94292 27.4242 3.9976 28.5276C4.20296 32.6714 4.81622 34.6012 6.10754 35.8926C7.39886 37.1838 9.32864 37.7972 13.4726 38.0024C14.5758 38.0572 15.4258 38.9958 15.3711 40.099C15.3164 41.2022 14.3778 42.0522 13.2746 41.9976C9.01964 41.7868 5.7129 41.1548 3.27912 38.721C0.845337 36.2872 0.213377 32.9804 0.00249692 28.7256C-0.0521831 27.6222 0.797837 26.6836 1.90104 26.629ZM40.099 26.629C41.2022 26.6836 42.0522 27.6222 41.9976 28.7256C41.7868 32.9804 41.1548 36.2872 38.721 38.721C36.2872 41.1548 32.9804 41.7868 28.7256 41.9976C27.6222 42.0522 26.6836 41.2022 26.629 40.099C26.5742 38.9958 27.4242 38.0572 28.5276 38.0024C32.6714 37.7972 34.6012 37.1838 35.8926 35.8926C37.1838 34.6012 37.7972 32.6714 38.0024 28.5276C38.0572 27.4242 38.9958 26.5742 40.099 26.629Z" fill="#9371F0" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 9.5C16.8462 9.5 13.2794 11.1329 10.5132 13.0611C7.74728 14.9891 5.60808 17.383 4.46284 18.8168C4.07556 19.2936 3.5 20.0022 3.5 21C3.5 21.9978 4.07556 22.7064 4.46284 23.1832C5.60808 24.617 7.74728 27.011 10.5132 28.9388C13.2794 30.867 16.8462 32.5 21 32.5C25.1538 32.5 28.7206 30.867 31.4868 28.9388C34.2528 27.011 36.392 24.617 37.5372 23.1832C37.9244 22.7064 38.5 21.9978 38.5 21C38.5 20.0022 37.9244 19.2936 37.5372 18.8168C36.392 17.383 34.2528 14.9891 31.4868 13.0611C28.7206 11.1329 25.1538 9.5 21 9.5ZM21 26.5C17.9624 26.5 15.5 24.0376 15.5 21C15.5 17.9624 17.9624 15.5 21 15.5C24.0376 15.5 26.5 17.9624 26.5 21C26.5 24.0376 24.0376 26.5 21 26.5Z" fill="#9371F0" />
                                    </svg>
                                </span>
                                <h3 className='mt-2'><b>Our Vision</b></h3>
                                <p className='opacity-50 m-0'>
                                    Our vision is to lead the future of AI-powered image generation, making creative innovation accessible to all. We aim to empower users.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 p-2">
                            <div className="about-company-card text-start">
                                <span>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M37.4484 1.61008C37.3092 1.09292 36.9048 0.68922 36.3874 0.55092C35.87 0.4126 35.318 0.56066 34.9394 0.93938C34.6862 1.19244 34.4244 1.46464 34.1496 1.7504C31.8284 4.16444 28.5774 7.54518 21.7878 8.51512C14.2788 9.58784 8.5 14.6124 8.5 22C8.5 23.652 8.79674 25.2348 9.33982 26.6978C11.146 25.8528 13.2388 25.1438 15.6364 24.5444C21.6298 23.0462 25.8512 19.2856 28.7848 15.2303C29.2704 14.5591 30.2082 14.4086 30.8794 14.8941C31.5506 15.3797 31.701 16.3174 31.2156 16.9886C28.0026 21.43 23.2374 25.7366 16.364 27.4548C14.1251 28.0146 12.253 28.6552 10.6922 29.3774C13.1023 33.064 17.2666 35.5 22 35.5C26.3242 35.5 32.1094 33.8804 35.8478 28.6188C39.5886 23.354 41.0066 14.826 37.4484 1.61008Z" fill="#9371F0" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.33982 26.6978C7.63646 27.4946 6.18796 28.4126 4.97902 29.474C2.44646 31.6974 1.06982 34.4578 0.520645 37.753C0.384445 38.5702 0.936485 39.343 1.75362 39.4792C2.57078 39.6154 3.34362 39.0634 3.47982 38.2462C3.93062 35.5414 5.01191 33.4372 6.95829 31.7284C7.94057 30.8662 9.16795 30.0828 10.6922 29.3774C10.1471 28.5436 9.69174 27.6458 9.33982 26.6978Z" fill="#9371F0" />
                                    </svg>
                                </span>
                                <h3 className='mt-2'><b>Our Resources</b></h3>
                                <p className='opacity-50 m-0'>
                                    Our platform offers powerful APIs and comprehensive support for seamless AI image generation. With detailed documentation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer data={{ key: 'About Us' }} />
        </>
    );
};

export default AboutUs;
