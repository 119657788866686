import React, { useEffect, useState } from "react";
import Header from "./UI/Header";
import Footer from "./UI/Footer";

const Home = () => {
  const [imageSrc, setImageSrc] = useState({
    gallery: "/assets/img/Photo_Gallary.png",
    select: "/assets/img/Select_Image.png",
    person: "/assets/img/Person_Design.png",
    review: "/assets/img/Review.png",
    Select_Your_Headshot: "/assets/img/Select_Your_Headshot.png",
  });

  const [responsiveImageSrc, setResponsiveImageSrc] = useState({
    gallery: "/assets/img/Mobile_Photo_Gallary.png",
    select: "/assets/img/Mobile_Select_Image.png",
    person: "/assets/img/Mobile_Person_Design.png",
    review: "/assets/img/Mobile_Review.png",
    Select_Your_Headshot: "/assets/img/Mobile_Select_Your_Headshot.png",
  });

  useEffect(() => {
    const updateImageSrc = () => {
      const isMobile = window.innerWidth <= 768;
      setResponsiveImageSrc({
        gallery: isMobile
          ? "/assets/img/Mobile_Photo_Gallary.png"
          : imageSrc.gallery,
        select: isMobile
          ? "/assets/img/Mobile_Select_Image.png"
          : imageSrc.select,
        person: isMobile
          ? "/assets/img/Mobile_Person_Design.png"
          : imageSrc.person,
        review: isMobile ? "/assets/img/Mobile_Review.png" : imageSrc.review,
        Select_Your_Headshot: isMobile
          ? "/assets/img/Mobile_Select_Your_Headshot.png"
          : imageSrc.Select_Your_Headshot,
      });
    };

    updateImageSrc(); // Check on initial render
    window.addEventListener("resize", updateImageSrc); // Update on resize

    return () => window.removeEventListener("resize", updateImageSrc); // Cleanup event listener
  }, [imageSrc]);

  return (
    <>
      <Header data={{ key: "Home" }} />

      {/* Hero Section */}
      <div className="container text-center section-padding">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 text-center">
            <button
              className="custom-btn-2"
              style={{ background: "#9371F014", color: "#9371F0" }}
            >
              25 MILLION+ AI HEADSHOTS GENERATED
            </button>
            <h1 className="heading mt-4">
              Integrate The World’s Most Popular AI Headshots
            </h1>
            <p className="subheading mt-2">
              Create professional AI-generated headshots in minutes with
              APIAdmin – perfect for business or personal branding.
            </p>
            <button className="custom-btn mt-3">
              <span className="pe-2">
                <svg
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 10.75C17.6862 10.75 17.4055 10.9454 17.2966 11.2397L17.1861 11.5384C17.1094 11.7456 17.0363 11.9229 16.9983 12.0041C16.9171 12.042 16.7456 12.1094 16.5384 12.1861L16.2397 12.2966C15.9454 12.4055 15.75 12.6862 15.75 13C15.75 13.3138 15.9454 13.5945 16.2397 13.7034L16.5384 13.8139C16.7456 13.8906 16.9171 13.958 16.9983 13.9959C17.0363 14.0771 17.1094 14.2544 17.1861 14.4616L17.2966 14.7603C17.4055 15.0546 17.6862 15.25 18 15.25C18.3138 15.25 18.5945 15.0546 18.7034 14.7603L18.8139 14.4616C18.8906 14.2544 18.9637 14.0771 19.0017 13.9959C19.0829 13.958 19.2544 13.8906 19.4616 13.8139L19.7603 13.7034C20.0546 13.5945 20.25 13.3138 20.25 13C20.25 12.6862 20.0546 12.4055 19.7603 12.2966L19.4616 12.1861C19.2544 12.1094 19.0829 12.042 19.0017 12.0041C18.9637 11.9229 18.8906 11.7456 18.8139 11.5384L18.7034 11.2397C18.5945 10.9454 18.3138 10.75 18 10.75Z"
                    fill="white"
                  />
                  <path
                    d="M12.9866 5.93968C13.4969 5.68677 14.0961 5.68677 14.6065 5.93968C14.8791 6.07479 15.1707 6.36755 15.401 6.5989C15.6324 6.82922 15.9251 7.12078 16.0602 7.39343C16.3131 7.90378 16.3131 8.50298 16.0602 9.0133C15.9251 9.286 15.6918 9.5183 15.4604 9.7486L13.5606 11.6485L10.3513 8.43942L12.2513 6.53947C12.4816 6.30812 12.7139 6.07479 12.9866 5.93968Z"
                    fill="white"
                  />
                  <path
                    d="M9.2908 9.5L12.5 12.709L4.74861 20.4604C4.51828 20.6918 4.28598 20.9251 4.01333 21.0602C3.50298 21.3131 2.90378 21.3131 2.39343 21.0602C2.12078 20.9251 1.82922 20.6324 1.5989 20.401C1.36755 20.1707 1.07479 19.8791 0.939682 19.6065C0.686772 19.0961 0.686772 18.4969 0.939682 17.9866C1.07479 17.7139 1.30812 17.4816 1.53947 17.2513L9.2908 9.5Z"
                    fill="white"
                  />
                  <path
                    d="M9 0.75C8.6862 0.75 8.40552 0.9454 8.29661 1.23972L8.18607 1.53845C8.10942 1.7456 8.03627 1.92286 7.99833 2.00408C7.91711 2.04203 7.7456 2.10942 7.53845 2.18607L7.23972 2.29661C6.9454 2.40552 6.75 2.68617 6.75 3C6.75 3.31383 6.9454 3.59448 7.23972 3.70339L7.53845 3.81393C7.7456 3.89058 7.91711 3.95797 7.99833 3.99592C8.03627 4.07714 8.10942 4.2544 8.18607 4.46155L8.29661 4.76028C8.40552 5.0546 8.6862 5.25 9 5.25C9.3138 5.25 9.5945 5.0546 9.7034 4.76028L9.8139 4.46155C9.8906 4.2544 9.9637 4.07714 10.0017 3.99592C10.0829 3.95797 10.2544 3.89058 10.4616 3.81393L10.7603 3.70339C11.0546 3.59448 11.25 3.31383 11.25 3C11.25 2.68617 11.0546 2.40552 10.7603 2.29661L10.4616 2.18607C10.2544 2.10942 10.0829 2.04203 10.0017 2.00408C9.9637 1.92286 9.8906 1.7456 9.8139 1.53845L9.7034 1.23972C9.5945 0.9454 9.3138 0.75 9 0.75Z"
                    fill="white"
                  />
                  <path
                    d="M18 0.75C17.6862 0.75 17.4055 0.9454 17.2966 1.23972L17.1861 1.53845C17.1094 1.7456 17.0363 1.92286 16.9983 2.00408C16.9171 2.04203 16.7456 2.10942 16.5384 2.18607L16.2397 2.29661C15.9454 2.40552 15.75 2.68617 15.75 3C15.75 3.31383 15.9454 3.59448 16.2397 3.70339L16.5384 3.81393C16.7456 3.89058 16.9171 3.95797 16.9983 3.99592C17.0363 4.07714 17.1094 4.2544 17.1861 4.46155L17.2966 4.76028C17.4055 5.0546 17.6862 5.25 18 5.25C18.3138 5.25 18.5945 5.0546 18.7034 4.76028L18.8139 4.46155C18.8906 4.2544 18.9637 4.07714 19.0017 3.99592C19.0829 3.95797 19.2544 3.89058 19.4616 3.81393L19.7603 3.70339C20.0546 3.59448 20.25 3.31383 20.25 3C20.25 2.68617 20.0546 2.40552 19.7603 2.29661L19.4616 2.18607C19.2544 2.10942 19.0829 2.04203 19.0017 2.00408C18.9637 1.92286 18.8906 1.7456 18.8139 1.53845L18.7034 1.23972C18.5945 0.9454 18.3138 0.75 18 0.75Z"
                    fill="white"
                  />
                </svg>
              </span>
              Access API
            </button>
          </div>
        </div>
      </div>

      <div className="px-2">
        <div className="gallery-section">
          <img
            src={responsiveImageSrc.gallery}
            className="w-100"
            alt="Gallery"
          />
        </div>
      </div>

      <div className="info-section-main section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center d-flex flex-wrap">
              <div className="col-12">
                <div className="col-12 col-md-7 text-center mx-auto">
                  <h6 className="rounded-heading mx-auto">1</h6>
                  <h2 className="heading mt-4">Upload a few photos</h2>
                  <p className="subheading mt-1">
                    Upload a few photos of yourself, and we'll take care of the
                    rest.
                  </p>
                </div>
                <img
                  src={responsiveImageSrc.select}
                  className="w-100"
                  alt="Select"
                />
              </div>
              <div className="col-12 mt-5">
                <div className="col-12 col-md-6 text-center mx-auto">
                  <h6 className="rounded-heading mx-auto">2</h6>
                  <h2 className="heading mt-4">Our AI gets to work</h2>
                  <p className="subheading mt-1">
                    {`{We use cutting-edge technology built by AI researchers
                                        from Meta and Microsoft to create your headshots.
                                        99% of users get their photos within 19 minutes}`}
                  </p>
                </div>
                <img
                  src={responsiveImageSrc.person}
                  className="w-100"
                  alt="AI Work"
                />
              </div>
              <div className="col-12 mt-5">
                <div className="col-12 col-md-8 text-center mx-auto">
                  <h6 className="rounded-heading mx-auto">3</h6>
                  <h2 className="heading mt-4">Select your headshots</h2>
                  <p className="subheading mt-1">
                    You'll receive different backgrounds, poses, and styles to
                    choose the perfect AI headshot. Ready for all use cases,
                    from personal to the most professional.
                  </p>
                </div>
                <img
                  src={responsiveImageSrc.Select_Your_Headshot}
                  className="w-100"
                  alt="Select Headshots"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h2 className="heading">
                <span style={{ color: "#9371F0" }}>10 Million +</span>
                AI Image Headshots Generated
              </h2>
              <p className="subheading mt-2">
                Read what people are saying about their new headshots
              </p>

              <button className="custom-btn">
                <span className="pe-2">
                  <svg
                    width="21"
                    height="22"
                    viewBox="0 0 21 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 10.75C17.6862 10.75 17.4055 10.9454 17.2966 11.2397L17.1861 11.5384C17.1094 11.7456 17.0363 11.9229 16.9983 12.0041C16.9171 12.042 16.7456 12.1094 16.5384 12.1861L16.2397 12.2966C15.9454 12.4055 15.75 12.6862 15.75 13C15.75 13.3138 15.9454 13.5945 16.2397 13.7034L16.5384 13.8139C16.7456 13.8906 16.9171 13.958 16.9983 13.9959C17.0363 14.0771 17.1094 14.2544 17.1861 14.4616L17.2966 14.7603C17.4055 15.0546 17.6862 15.25 18 15.25C18.3138 15.25 18.5945 15.0546 18.7034 14.7603L18.8139 14.4616C18.8906 14.2544 18.9637 14.0771 19.0017 13.9959C19.0829 13.958 19.2544 13.8906 19.4616 13.8139L19.7603 13.7034C20.0546 13.5945 20.25 13.3138 20.25 13C20.25 12.6862 20.0546 12.4055 19.7603 12.2966L19.4616 12.1861C19.2544 12.1094 19.0829 12.042 19.0017 12.0041C18.9637 11.9229 18.8906 11.7456 18.8139 11.5384L18.7034 11.2397C18.5945 10.9454 18.3138 10.75 18 10.75Z"
                      fill="white"
                    />
                    <path
                      d="M12.9866 5.93968C13.4969 5.68677 14.0961 5.68677 14.6065 5.93968C14.8791 6.07479 15.1707 6.36755 15.401 6.5989C15.6324 6.82922 15.9251 7.12078 16.0602 7.39343C16.3131 7.90378 16.3131 8.50298 16.0602 9.0133C15.9251 9.286 15.6918 9.5183 15.4604 9.7486L13.5606 11.6485L10.3513 8.43942L12.2513 6.53947C12.4816 6.30812 12.7139 6.07479 12.9866 5.93968Z"
                      fill="white"
                    />
                    <path
                      d="M9.2908 9.5L12.5 12.709L4.74861 20.4604C4.51828 20.6918 4.28598 20.9251 4.01333 21.0602C3.50298 21.3131 2.90378 21.3131 2.39343 21.0602C2.12078 20.9251 1.82922 20.6324 1.5989 20.401C1.36755 20.1707 1.07479 19.8791 0.939682 19.6065C0.686772 19.0961 0.686772 18.4969 0.939682 17.9866C1.07479 17.7139 1.30812 17.4816 1.53947 17.2513L9.2908 9.5Z"
                      fill="white"
                    />
                    <path
                      d="M9 0.75C8.6862 0.75 8.40552 0.9454 8.29661 1.23972L8.18607 1.53845C8.10942 1.7456 8.03627 1.92286 7.99833 2.00408C7.91711 2.04203 7.7456 2.10942 7.53845 2.18607L7.23972 2.29661C6.9454 2.40552 6.75 2.68617 6.75 3C6.75 3.31383 6.9454 3.59448 7.23972 3.70339L7.53845 3.81393C7.7456 3.89058 7.91711 3.95797 7.99833 3.99592C8.03627 4.07714 8.10942 4.2544 8.18607 4.46155L8.29661 4.76028C8.40552 5.0546 8.6862 5.25 9 5.25C9.3138 5.25 9.5945 5.0546 9.7034 4.76028L9.8139 4.46155C9.8906 4.2544 9.9637 4.07714 10.0017 3.99592C10.0829 3.95797 10.2544 3.89058 10.4616 3.81393L10.7603 3.70339C11.0546 3.59448 11.25 3.31383 11.25 3C11.25 2.68617 11.0546 2.40552 10.7603 2.29661L10.4616 2.18607C10.2544 2.10942 10.0829 2.04203 10.0017 2.00408C9.9637 1.92286 9.8906 1.7456 9.8139 1.53845L9.7034 1.23972C9.5945 0.9454 9.3138 0.75 9 0.75Z"
                      fill="white"
                    />
                    <path
                      d="M18 0.75C17.6862 0.75 17.4055 0.9454 17.2966 1.23972L17.1861 1.53845C17.1094 1.7456 17.0363 1.92286 16.9983 2.00408C16.9171 2.04203 16.7456 2.10942 16.5384 2.18607L16.2397 2.29661C15.9454 2.40552 15.75 2.68617 15.75 3C15.75 3.31383 15.9454 3.59448 16.2397 3.70339L16.5384 3.81393C16.7456 3.89058 16.9171 3.95797 16.9983 3.99592C17.0363 4.07714 17.1094 4.2544 17.1861 4.46155L17.2966 4.76028C17.4055 5.0546 17.6862 5.25 18 5.25C18.3138 5.25 18.5945 5.0546 18.7034 4.76028L18.8139 4.46155C18.8906 4.2544 18.9637 4.07714 19.0017 3.99592C19.0829 3.95797 19.2544 3.89058 19.4616 3.81393L19.7603 3.70339C20.0546 3.59448 20.25 3.31383 20.25 3C20.25 2.68617 20.0546 2.40552 19.7603 2.29661L19.4616 2.18607C19.2544 2.10942 19.0829 2.04203 19.0017 2.00408C18.9637 1.92286 18.8906 1.7456 18.8139 1.53845L18.7034 1.23972C18.5945 0.9454 18.3138 0.75 18 0.75Z"
                      fill="white"
                    />
                  </svg>
                </span>
                Access API
              </button>
            </div>
          </div>
        </div>
        <div>
          <img
            src={responsiveImageSrc.review}
            className="w-100 my-5"
            alt="Review"
          />
        </div>
      </div>

      <div className="counter-box-main">
        <div className="container">
          <div className="row flex-wrap align-items-center justify-content-center">
            <div className="col-12 col-md-4 py-2 text-center counter-single-box">
              <h4 className="heading">800K+</h4>
              <h5 className="subheading mb-0">API Admin Users</h5>
            </div>
            <div className="col-12 col-md-4 py-2 text-center counter-single-box custom-mx-border">
              <h4 className="heading">1.3M+</h4>
              <h5 className="subheading mb-0">Monthly Headshots</h5>
            </div>
            <div className="col-12 col-md-4 py-2 text-center counter-single-box">
              <h4 className="heading">450+</h4>
              <h5 className="subheading mb-0">Customer hours saved</h5>
            </div>
          </div>
        </div>
      </div>

      <div
        className="section-padding"
        style={{ borderBottom: "3px solid #F5F5F5" }}
      >
        <div className="container">
          <div className="row flex-wrap Privay-First-Section">
            <div className="col-12 text-center">
              <h2 className="heading">
                <span className="pe-2" style={{ color: "#9371F0" }}>
                  Privacy first
                </span>
                Protect your data
              </h2>
              <h5 className="subheading mt-2">
                API Admin will never sell your information.
              </h5>
            </div>
            <div className="col-6 col-md-3 p-2 pe-4 custom-box-for-privacy">
              <img
                src="/assets/img/protect-data-section-img-1.png"
                alt=""
                style={{ width: "100%", maxWidth: "150px" }}
              />
              <h4 className="heading mt-3" style={{ fontSize: "28px" }}>
                Advanced Encryption
              </h4>
              <p className="subheading mt-1" style={{ fontSize: "26px" }}>
                Your data deserves the best protection. We encrypt sensitive.
              </p>
            </div>
            <div className="col-6 col-md-3 p-2 pe-4 custom-box-for-privacy">
              <img
                src="/assets/img/protect-data-section-img-2.png"
                alt=""
                style={{ width: "100%", maxWidth: "150px" }}
              />
              <h4 className="heading mt-3" style={{ fontSize: "28px" }}>
                Never Sell Your data
              </h4>
              <p className="subheading mt-1" style={{ fontSize: "26px" }}>
                API Admin will never sell your data to any third party.
              </p>
            </div>
            <div className="col-6 col-md-3 p-2 pe-4 custom-box-for-privacy">
              <img
                src="/assets/img/protect-data-section-img-3.png"
                alt=""
                style={{ width: "100%", maxWidth: "150px" }}
              />
              <h4 className="heading mt-3" style={{ fontSize: "28px" }}>
                Live Support
              </h4>
              <p className="subheading mt-1" style={{ fontSize: "26px" }}>
                Contact us anytime from 7 AM to 10 PM, Monday to Friday.
              </p>
            </div>
            <div className="col-6 col-md-3 p-2 pe-4 custom-box-for-privacy">
              <img
                src="/assets/img/protect-data-section-img-4.png"
                alt=""
                style={{ width: "100%", maxWidth: "150px" }}
              />
              <h4 className="heading mt-3" style={{ fontSize: "28px" }}>
                You're in Control
              </h4>
              <p className="subheading mt-1" style={{ fontSize: "26px" }}>
                We will never use your photos to train new AI models.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="faq-main section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 text-center">
              <h2 className="heading">Frequently asked questions</h2>
              <h2 className="subheading my-2" style={{ fontWeight: "600" }}>
                Have more questions? You can email us at
                <span style={{ color: "#9371F0" }} className="ps-2">
                  support@apiadmin.ai
                </span>
              </h2>
              <div className="accordion faq-accordion" id="faqAccordion">
                <div className="accordion-item">
                  <h2
                    className="accordion-header d-flex align-items-center justify-content-between"
                    id="headingOne"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                      onClick={(e) => {
                        const button = e.currentTarget;
                        const isExpanded =
                          button.getAttribute("aria-expanded") === "true";
                        const allButtons =
                          document.querySelectorAll(".accordion-button");
                        const allIcons = document.querySelectorAll(".faq-icon");
                        allButtons.forEach((btn) =>
                          btn.setAttribute("aria-expanded", "false")
                        );
                        allIcons.forEach((icon) => (icon.textContent = "+"));
                        button.setAttribute("aria-expanded", !isExpanded);
                        const icon = button.nextElementSibling;
                        if (isExpanded) {
                          icon.textContent = "-";
                        } else {
                          icon.textContent = "+";
                        }
                      }}
                    >
                      What kind of photos do I need to upload?
                    </button>
                    <span className="faq-icon plus-icon">+</span>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Neque quam aliquid ab rerum? Unde, dignissimos beatae!
                      Fugiat quidem tempore quisquam rerum delectus, est, quo
                      fugit nobis, esse tempora ut. At?
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-header d-flex align-items-center justify-content-between"
                    id="headingTwo"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      onClick={(e) => {
                        const button = e.currentTarget;
                        const isExpanded =
                          button.getAttribute("aria-expanded") === "true";
                        const allButtons =
                          document.querySelectorAll(".accordion-button");
                        const allIcons = document.querySelectorAll(".faq-icon");
                        allButtons.forEach((btn) =>
                          btn.setAttribute("aria-expanded", "false")
                        );
                        allIcons.forEach((icon) => (icon.textContent = "+"));
                        button.setAttribute("aria-expanded", !isExpanded);
                        const icon = button.nextElementSibling;
                        if (isExpanded) {
                          icon.textContent = "-";
                        } else {
                          icon.textContent = "+";
                        }
                      }}
                    >
                      What resolution are your pictures?
                    </button>
                    <span className="faq-icon plus-icon">+</span>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      {/* Your answer content here */}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-header d-flex align-items-center justify-content-between"
                    id="headingThree"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                      onClick={(e) => {
                        const button = e.currentTarget;
                        const isExpanded =
                          button.getAttribute("aria-expanded") === "true";
                        const allButtons =
                          document.querySelectorAll(".accordion-button");
                        const allIcons = document.querySelectorAll(".faq-icon");
                        allButtons.forEach((btn) =>
                          btn.setAttribute("aria-expanded", "false")
                        );
                        allIcons.forEach((icon) => (icon.textContent = "+"));
                        button.setAttribute("aria-expanded", !isExpanded);
                        const icon = button.nextElementSibling;
                        if (isExpanded) {
                          icon.textContent = "-";
                        } else {
                          icon.textContent = "+";
                        }
                      }}
                    >
                      What are my rights regarding the use of my photos?
                    </button>
                    <span className="faq-icon plus-icon">+</span>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      {/* Your answer content here */}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2
                    className="accordion-header d-flex align-items-center justify-content-between"
                    id="headingFour"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                      onClick={(e) => {
                        const button = e.currentTarget;
                        const isExpanded =
                          button.getAttribute("aria-expanded") === "true";
                        const allButtons =
                          document.querySelectorAll(".accordion-button");
                        const allIcons = document.querySelectorAll(".faq-icon");
                        allButtons.forEach((btn) =>
                          btn.setAttribute("aria-expanded", "false")
                        );
                        allIcons.forEach((icon) => (icon.textContent = "+"));
                        button.setAttribute("aria-expanded", !isExpanded);
                        const icon = button.nextElementSibling;
                        if (isExpanded) {
                          icon.textContent = "-";
                        } else {
                          icon.textContent = "+";
                        }
                      }}
                    >
                      I love your product! How can I spread the word?
                    </button>
                    <span className="faq-icon plus-icon">+</span>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body">
                      {/* Your answer content here */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className="section-padding">
        <div className="container">
          <div className="row flex-wrap">
            <div className="col-12">
              <div className="text-center">
                <h2 className="heading">
                  Boost User Delight and Revenue Growth
                </h2>
                <p className="subheading mt-2">
                  Enhance user experience while driving sustainable revenue
                  expansion
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-end">
              <img
                src="/assets/img/contact_us_img.png"
                className="col-12 col-md-8 col-xl-6"
                style={{ objectFit: "contain" }}
              />
            </div>
            <div className="col-12 col-md-6">
              <div className="contact-form-main">
                <h2 className="subheading opacity-100">
                  Delighting your users while expanding your revenue streams
                </h2>
                <p className="opacity-50">
                  The future is here! Give your users a 21st century experience
                  while making a profit. Price our services as you see fit and
                  make revenue on top of our usual pricing. Once you integrate
                  our APIs and decide which AI products to sell, our platform
                  handles all of the AI modelling, generation and even image
                  storage.
                </p>
                <button className="custom-btn d-flex align-items-center">
                  Contact Us
                  <span className="ps-2">
                    <svg
                      width="18"
                      height="13"
                      viewBox="0 0 18 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 6.93848H1"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.0003 11.9385C12.0003 11.9385 17.0002 8.25608 17.0002 6.93848C17.0002 5.62088 12.0002 1.93848 12.0002 1.93848"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer data={{ key: "Home" }} />
    </>
  );
};

export default Home;
